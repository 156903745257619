import Vue from "vue";

const host = process.env.VUE_APP_API_SERVER;

const routes = {
  user: host + "/api/admin/user",
  company: host + "/api/admin/company",
  role: host + "/api/admin/role",
  roleUser: host + "/api/admin/user/role",
  zone: host + "/api/admin/zone",
  group: host + "/api/admin/group",
  companyAddress: host + "/api/admin/company/address",
  loan: host + "/api/admin/request/loan",
  client: host + "/api/admin/client",
  setting: host + "/api/admin/setting",
  report: host + "/api/admin/report",
  catalogue: host + "/api/admin/catalogue",
  paymentDetail: host + "/api/admin/payment/detail",
  payment: host + "/api/admin/payment",
  alert: host + "/api/admin/alert",
  agreement: host + "/api/admin/agreement",
  sheetOneReport: host + "/api/admin/report/sheetOne",
  deliveryReport: host + "/api/admin/report/delivery",
  loanRequestHistory: host + "/api/admin/history/loanRequest",
  defaulter: host + "/api/admin/history/defaulter",
  dashboardReport: host + "/api/admin/report/dashboard",
};
const statusType = ['NUEVO', 'SUPERVISOR', 'COORDINADOR', 'VALIDACION', 'COORDINACION_GENERAL', 'ENTREGA', 'ENTREGA_CLIENTE', 'ACTIVO', 'CERRADO', 'RECHAZADO', 'APLICADO', 'CANCELADO', 'DEMANDA',]

export default {
  routes,
  statusType
};
